<template>
    <div>
        <headers-component />
        <!-- style="min-height: 90vh;" -->
        <div id="content" :style="'margin-top: ' + headersHeight + 'px; margin-bottom: ' + bottomHeight + 'px;'">
            <transition>
                <router-view></router-view>
            </transition>
        </div>
        <footer-component />
        <!-- <mobile-footer v-else />
        <SocialChat icon :attendants="attendants">
            <p slot="header">Click on one of our attendants below to chat on WhatsApp.</p>
            <template v-slot:button>
                <img src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg" alt="icon whatsapp" aria-hidden="true">
            </template>
            <small slot="footer">Opening hours: 8am to 6pm</small>
        </SocialChat> -->
    </div>
</template>
<script>
// import { SocialChat } from 'vue-social-chat'
export default {
    data () {
        return {
            headersHeight: 0,
            attendants: [
                {
                    app: 'whatsapp',
                    label: 'Technical support',
                    name: 'Elite Center',
                    number: '+628117709168',
                    avatar: {
                        src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
                        alt: 'Alan Ktquez avatar'
                    }
                }
                // {
                //     app: 'telegram',
                //     label: 'Technical support',
                //     name: 'Handani Andri',
                //     username: 'HandaniAndri',
                //     avatar: {
                //         src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
                //         alt: 'Alan Ktquez avatar'
                //     }
                // }
            ]
        }
    },
    computed: {
        headerActive () {
            return this.$headerActive.value
        }
    },
    watch: {
        headerActive () {
            console.log('running check header active', this.headerActive)
            if (this.headerActive) this.init()
            else this.headersHeight = 0
        }
    },
    components: {
        HeadersComponent: () => import('./components/headers'),
        FooterComponent: () => import('./components/footers')
        // MobileFooter: () => import('./components/MobileFooter'),
        // SocialChat
    },
    methods: {
        async init () {
            await this.getCompany()
            setTimeout(() => {
                var headerElement = document.getElementById('headers')
                if (this.headerActive) this.headersHeight = headerElement.clientHeight
                else this.headersHeight = 0
            }, 500)
        },
        setMargin () {
            if (this.windowWidth < 600 && this.$route.path.search('detail') <= -1) return this.headersHeight
            else if (this.windowWidth < 600 && this.$route.path.search('detail') >= 0) return 0
            else return this.headersHeight
        }
    },
    mounted () {
        this.init()
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep img {
        margin: auto;
    }
</style>
